<template>
    <div class="mainView">


        <!-- <div> -->
            <!-- <img class="imgStyle" src="../../assets/optionIcon.png"> -->

            <!-- <el-select v-model="currentGroup" placeholder="请选择" class="optionStyle" @change="refreshGroupAwards">
                <el-option
                v-for="item in group_list"
                :key="item"
                :label="item"
                :value="item"
                
                >
                </el-option>
            </el-select>
            
        </div> -->

                
        <el-table
            :data="team_total_awards"

            stripe
            :row-class-name="tableRowClassName"
            :cell-style="tableRowStyle"
            :header-cell-style="{background: '#00348b', color :'#fff'}"
            empty-text=""
            class="tableStyle">
            <!-- <el-table-column
            prop="real_num"
            label="全国排名"
            width="60">
            </el-table-column> -->

            <el-table-column label="学生姓名">
              <template slot-scope="scope">
                {{
                  scope.row.student.student_lastName +
                  scope.row.student.student_givenName
                }}
              </template>
            </el-table-column>
            <el-table-column label="学校">
              <template slot-scope="scope">
                {{ scope.row.account.account_name }}
              </template>
            </el-table-column>

            <el-table-column label="赛区">
              <template slot-scope="scope">
                {{ scope.row.group_name }}
              </template>
            </el-table-column>

            <el-table-column label="排名">
            <template slot-scope="scope">
              {{ scope.row.real_rank }}
            </template>
          </el-table-column>

            <el-table-column
            prop="total_score"
            label="分数" width="100">
            </el-table-column>

            <!-- <el-table-column
            prop="award_show_lable"
            label="奖项" >
            </el-table-column> -->

            <!-- <el-table-column
            prop="submit_time"
            label="交卷时间">
            </el-table-column> -->

        </el-table>



    </div>
</template>

<script>
import {getScoresByEvent,getScoresSelf,getEventScores,getTeamTotalAwards} from '../../api/index'
import {getUserId,getProjectCode} from '../../utils/store'
import '../../assets/common/font.css'

export default {
    data(){
        return{
            events:[
               
            ],
            currentEvent:'',
            rankList:[],
            personalScores:[],
            tableData: [
                ],
               
            value: '',
             awards: [],
            total_awards: [],
            team_total_awards: [],
            event_awards: [],
            event_awards_all: [],
            team_event_awards_all: [],
            event_awards_arr: {},
            award_id: '',
             awardLab: 'National',
            group_list: [],
            currentGroup: '',
            team_total_awards_all: [],
            currentServer: '全部',
            project_server: [],
            award_lable: '',
            accounts: [],
            currentAccount: '全部',
            rise_list:['晋级','候补','未晋级']
        }
    },
    mounted(){
        // this.getScoresByEvent()
        // this.getScoresSelf()

        this.getEventScores()
    },
    methods:{
        getEventScores(){
            getTeamTotalAwards(getProjectCode(),getUserId()).then((res) => {
              if (res.data.code === 0) {
                // 挑选个人总分奖
                this.total_awards = res.data.data.filter(
                  (item) => item.award_type === 'personal total'
                )
                const group_list = []
                let team_total_awards = res.data.data.filter((item) => {
                  if (item.group_name != undefined){
                    item.groupIndex = Number(item.group_name.split('Group ')[1])
                    if (!group_list.includes(item.group_name)) {
                      group_list.push(item.group_name)
                    }
                  }
                  
                  return item.award_type === 'team total'
                })
                this.group_list = group_list


                if (this.group_list.length > 0){
                  this.currentGroup = this.group_list[0]
                }

                team_total_awards = team_total_awards.sort(
                  this.compare('real_rank', true)
                )
                team_total_awards = team_total_awards.sort(
                  this.compare('groupIndex', true)
                )
                this.team_total_awards_all = team_total_awards
                this.refreshGroupAwards()

                // 这个应该按照项目的评奖类型处理（个人总分讲是否金银铜等）
                // const gold = [];
                // const silver = [];
                // const bronze = [];

                // this.bronze = this.transferAwardArray(bronze);

                // this.silver = this.transferAwardArray(silver);
                // this.gold = this.transferAwardArray(gold);

                // 活的所有的项目
                const events = []
                const servers = []
                const accounts = []
                this.event_awards_all = res.data.data.filter((item) => {
                  // 单项奖的项目
                  if (item.award_type === 'event rank') {
                    if (!events.includes(item.application_event)) {
                      events.push(item.application_event)
                    }
                    if (!servers.includes(item.group_name)) {
                      servers.push(item.group_name)
                    }
                    if (!accounts.includes(item.account.account_name)) {
                      accounts.push(item.account.account_name)
                    }
                    return true
                  } else {
                    return false
                  }
                })
                this.changeEventView()
                console.log(222222222222, this.event_awards_all)
                this.events = events
                this.accounts = accounts

                this.project_server = servers

                this.events.splice(0, 0, '全部')
                this.project_server.splice(0, 0, '全部')

                for (const event of this.events) {
                  const event_awards = this.event_awards_all.filter(
                    (item) => item.application_event === event
                  )
                  this.event_awards_arr[event] = this.transferAwardArray(event_awards)
                }

                const team_events = []
                this.team_event_awards_all = res.data.data.filter((item) => {
                  // 单项奖的项目
                  if (item.award_type === 'event team rank') {
                    if (!team_events.includes(item.application_event)) {
                      team_events.push(item.application_event)
                    }
                    return true
                  } else {
                    return false
                  }
                })
                this.team_events = team_events

                this.team_events.splice(0, 0, '全部')

                // this.event_awards_all.sort(this.sortEvevtRealrank)
                // this.team_event_awards_all.sort(this.sortEvevtRealrank)
              }
            })
        },
        changeEventView () {
          this.event_awards = this.event_awards_all.filter((item) => {
            return (
              (this.currentEvent === '全部' ||
                item.application_event === this.currentEvent) &&
              (this.currentServer === '全部' ||
                item.group_name === this.currentServer) &&
              (this.currentAccount === '全部' ||
                item.account.account_name === this.currentAccount)
            )
          })
          this.event_awards.sort(this.sortEvevtScore)
          this.event_awards.sort(this.sortEvevtRealrank)
        },
        transferAwardArray (array) {
            // 倒序排
            array.reverse()
            const newArray = []
            let flag = 0
            for (const item of array) {
              if (flag < 10) {
                newArray.push(item)
                flag++
              } else {
                if (item.real_rank === newArray[newArray.length - 1].real_rank) {
                  newArray.push(item)
                  flag++
                } else {
                  newArray.push('pause')
                  newArray.push(item)
                  flag = 1
                }
              }
            }
            return newArray
          },

          // general
          rank (number) {
            var tail = number.toString().charAt(number.toString().length - 1, 1)
            if (tail === '1' && number !== 11) {
              return 'st'
            } else if (tail === '2' && number !== 12) {
              return 'nd'
            } else if (tail === '3' && number !== 13) {
              return 'rd'
            } else {
              return 'th'
            }
          },
          // general
          upfirstName (name) {
            return name.toLowerCase().replace(/\b([\w|‘]+)\b/g, function (word) {
              // return word.slice(0, 1).toUpperCase() + word.slice(1);
              return word.replace(word.charAt(0), word.charAt(0).toUpperCase())
            })
          },
          exportAccountPPTClick () {
            this.account_info.account_id = ''
            this.account_info.type = ''
            this.showAccountAwardDialog = true
          },
        compare (property, desc) {
          return function (a, b) {
            var value1 = a[property]
            var value2 = b[property]
            if (desc === true) {
              // 升序排列
              return value1 - value2
            } else {
              // 降序排列
              return value2 - value1
            }
          }
        },
        refreshGroupAwards () {
          if (this.currentGroup) {
            this.team_total_awards = this.team_total_awards_all.filter(
              (item) => item.group_name === this.currentGroup
            )
          } else {
            this.team_total_awards = [...this.team_total_awards_all]
          }
        },
        handleEventName(event){
            let event_array = event.split(" ")
            return event_array[0]
        },
        tableRowClassName({row, rowIndex}) {
            if (rowIndex%2 == 0) {
                return 'success-row';
            } 
            return '';
        },

        tableRowStyle({row, rowIndex}){
            return 'font-family: Montserrat-SemiBold;'
        },
        changeView(){
            this.getEventScores()
            console.log(1111,this.value)
        },
        getScoresByEvent(){
            getScoresByEvent(this.currentEvent).then(res=>{
                console.log(res)
                if(res.data.code===0){
                    this.rankList = res.data.data
                }
            })
        },
        getScoresSelf(){
            getScoresSelf(this.currentEvent,'608245c4a4640b84328b4575').then(res=>{
                console.log(res)
                if(res.data.code===0){
                    this.personalScores = res.data.data
                }
            })
        },
        chooseEvent(item){
            this.currentEvent = item
            this.getScoresByEvent()
            this.getScoresSelf()
        }
    }
}
</script>

<style lang="less">


.el-table .success-row {
   background: #D1E1FF;
}
.mainView{
    position: relative;
    margin-left: 40px;
    margin-top: 30px;
    border-radius: 40px;
}
.imgStyle{
    position:absolute;
    margin-top: 30px;
    margin-left: 0px;
    width: 25px;
    height: 22.5px;
}
.optionStyle{
    position:absolute;
    margin-left: 10%;
    margin-top: 20px;
    width: 300px;
    font-family: Montserrat-SemiBold;
}
.tableStyle{
   position:absolute;
   width: 80%;
   margin-left: 10%;
   margin-top: 20px;
   border-radius: 10px;
}
</style>